
const CONTEXT_PATH = 'http://13.53.243.59/back/public/index.php'


export const urls = {
    login: `${CONTEXT_PATH}/api/token`,
    events: `${CONTEXT_PATH}/events`,
    pacientes: `${CONTEXT_PATH}/pacientes`,
    Pacientes: `${CONTEXT_PATH}/pacientes`,
    Historial: `${CONTEXT_PATH}/historial`,
    tipos: `${CONTEXT_PATH}/types`,
    citanew: `${CONTEXT_PATH}/events/new`,


}