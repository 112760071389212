// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-expediente-tsx": () => import("./../../../src/pages/expediente.tsx" /* webpackChunkName: "component---src-pages-expediente-tsx" */),
  "component---src-pages-formbio-tsx": () => import("./../../../src/pages/formbio.tsx" /* webpackChunkName: "component---src-pages-formbio-tsx" */),
  "component---src-pages-header-1-tsx": () => import("./../../../src/pages/Header1.tsx" /* webpackChunkName: "component---src-pages-header-1-tsx" */),
  "component---src-pages-header-tsx": () => import("./../../../src/pages/Header.tsx" /* webpackChunkName: "component---src-pages-header-tsx" */),
  "component---src-pages-historial-tsx": () => import("./../../../src/pages/Historial.tsx" /* webpackChunkName: "component---src-pages-historial-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/Login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-qrcode-tsx": () => import("./../../../src/pages/qrcode.tsx" /* webpackChunkName: "component---src-pages-qrcode-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/Signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

